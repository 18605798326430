<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
        class="ms-section bg_image "
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>

    <div class="ms-right">
      <div class="ms-section ms-table">
        <div
          class="slipt-content-inner ms-tableCell story-content footer-widget rn-plr rn-ptb-100"
        >
          <div class="content">
            <h2 class="section-title">联系我们</h2>
            <div class="service-wrapper address">
              <!-- Start Single Service -->
              <div class="service" v-for="(contact, i) in contactInfo" :key="i">
                <h4>{{ contact.name }}</h4>
                <p>
                  {{ contact.desc }}
                </p>
              </div>
              <!-- End Single Service -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/slider/4.jpg"),
        contactInfo: [
          {
            name: "地址",
            desc: "山东省青岛市市北区辽宁路98号M2创意园1号楼A101",
            to: "",
          },
          {
            name: "邮箱",
            desc: "19410190@qq.com",
            to: "mailto:example@domain.com",
          },
          {
            name: "联系电话",
            desc: "17057516789",
            to: "tel:+496170961709",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
