<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
        class="ms-section bg_image "
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>
    <div class="ms-right">
      <div class="ms-section ms-table">
        <div class="slipt-content-inner ms-tableCell slider rn-plr rn-ptb-100">
          <div class="content">
            <span>{{ heroContent.name }}!</span>
            <h2>{{ heroContent.title }}</h2>
            <p class="">
              {{ heroContent.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/slider/1.jpg"),
        heroContent: {
          name: "欢迎了解我们",
          title: "“山东嘉晟科技服务有限公司”",
          desc: `“嘉晟科技”成立于2023年，是一家以“客户为中心、为 客户创造价值、可持续发展、合作共赢”为宗旨的科技创新 型服务企业。
 我们的业务涵盖了多个领域，包括但不限于共享充电控 制设备租赁，维修、运维、技术开发，服务咨询、数据处理 和存储支持、广告机研发、制造、销售、设计传媒广告发布 等，我们借助先进的物联网技术和大数据分析，实现产品的 智能调度和优化配置，确保用户能够随时随地享受到便捷服 务的同时，可以为中小微企业产品形象做广告宣传赋能。
 “山东嘉晟科技服务有限公司”位于美丽的海滨城市“ 青岛”,这里不仅是中国重要的经济中心之一，也是科技创 新和人才培养的沃土。依托青岛优越的地理位置和丰富的人 才资源，我们团队由一群经验丰富、技术精湛的专业人士组 成。他们具备深厚的行业知识和实践经验，能够多迅速应对各 种挑战，为客户提供量身定制的服务方案。在技术创新方面 ,我们始终保持敏锐的市场洞察力和前瞻性的思维。我们不 断投入研发资源，引进先进的技术和设备，以推动公司的技 术创新和产品升级。同时，我们也向国家版权局申请三项计 算机软件著作权(大数据分析看板、广告投放平台、广告管 理系统),为共同推动行业的进步和发展贡献自己的力量。
 除了专业技术和服务，我们也非常注重企业社会责任和
 可持续发展。我们致力于保护环境、关注员工福利、积极参 与公益事业，并倡导绿色低碳的生活方式。我们相信，企业 的成功不仅仅体现在经济效益上，更在于能否为社会创造更 大的价值。
 未来，  “山东嘉晟科技服务有限公司”将继续秉持创新 、合作、共赢的理念，积极拓展业务范围，提升服务水平， 努力成为共享经济领域的领军企业。我们期待与您携手共进 ,共享美好家园!`,
        },
      };
    },
  };
</script>

<style lang="scss" scoped></style>
