<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
        class="ms-section bg_image "
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>
    <div class="ms-right">
      <div class="ms-section ms-table">
        <div class="slipt-content-inner ms-tableCell rn-plr rn-ptb-100">
          <div class="story-content">
            <div class="text-left content">
              <h2 class="section-title"></h2>
              <p>
                我们的共享充电宝业务涵盖烟台、青岛、威海等地区
              </p>
              <div class="story-btn">
                <router-link to="/blog"
                  >点击查看</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/slider/2.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped></style>
